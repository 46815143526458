<template>
  <div class="table-wrap">
    <div class="search">
      <a-input-search
        v-model:value="queryParam.mapName"
        :placeholder="$t('LB_Coach_EnterName')"
        style="width: 220px"
        enter-button
        allow-clear
        @search="handleSearch"
      />
      <!-- 请输入名称 -->
    </div>
    <a-table
      :scroll="{ x: 884 }"
      :columns="columns"
      :data-source="state.dataSource"
      :rowKey="(record, index) => index"
      :bordered="false"
      :pagination="pagination"
      :loading="state.loading"
      @change="handleTableChange"
    >
      <template #taskName="{ text }">
        <a-tooltip placement="top">
          <template #title>{{ text }}</template>
          {{ text }}
        </a-tooltip>
      </template>
      <template #rate="{ text }"> {{ text }}% </template>
      <template #action="{ record }">
        <div class="action">
          <a-button type="link" @click="handleInfo('member', record)">{{
            $t("Pub_Tab_Detail")
          }}</a-button>
          <!-- 详情 -->
        </div>
      </template>
    </a-table>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { reactive, toRefs, toRaw } from "vue";
import { useFetchData } from "@/utils/useFetchData";
import { teachManageTrackList, mapDetail } from "@/api/map";
export default {
  setup(props, { emit }) {
    const { t: $t } = useI18n();
    const stateData = reactive({
      columns: [
        {
          title: $t("teaching.table.map_name"),
          // title: "学习地图名称",
          dataIndex: "taskName",
          slots: { customRender: "taskName" },
          width: 205,
          ellipsis: true,
        },
        {
          title: $t("teaching.table.finish"),
          // title: "完成",
          dataIndex: "complete",
          width: 90,
          align: "center",
        },
        {
          title: $t("teaching.table.in_progress"),
          // title: "进行中",
          dataIndex: "inProgress",
          width: 120,
          align: "center",
        },
        {
          title: $t("teaching.table.overdue"),
          // title: "逾期",
          dataIndex: "overdue",
          width: 90,
          align: "center",
        },
        {
          title: $t("teaching.table.completion_rate"),
          // title: "完成率",
          dataIndex: "completionRate",
          width: 150,
          align: "center",
          slots: { customRender: "rate" },
        },
        {
          title: $t("teaching.table.pass_rate"),
          // title: "通过率",
          dataIndex: "passRate",
          width: 105,
          align: "center",
          slots: { customRender: "rate" },
        },
        {
          title: $t("teaching.table.participation_rate"),
          // title: "参与率",
          dataIndex: "participationRate",
          width: 150,
          align: "center",
          slots: { customRender: "rate" },
        },
        {
          title: $t("teaching.table.operate"),
          // title: "操作",
          width: 90,
          align: "center",
          slots: { customRender: "action" },
        },
      ],
    });

    const getList = (params) => {
      let form = JSON.parse(JSON.stringify(queryParam));
      form.page = params.current;
      form.pageSize = params.pageSize;

      return teachManageTrackList(form).then((res) => {
        return {
          success: true,
          data: res.data.list,
          current: res.data.page,
          pageSize: res.data.pageSize,
          total: res.data.totals,
        };
      });
    };

    const {
      stripe,
      reload,
      setPageInfo,
      context: state,
    } = useFetchData(getList, {
      current: 1,
      pageSize: 30,
      tableSize: "middle", // 'default' | 'middle' | 'small'
      stripe: false,
    });

    const handleTableChange = ({ current, pageSize }, filters, sorter) => {
      if (sorter.field != undefined) {
        if (
          sorter.field != queryParam.sortKey ||
          sorter.order != queryParam.sort
        ) {
          current = 1;
        }
      }
      queryParam.sortKey = sorter.field;
      queryParam.sort = sorter.order;

      setPageInfo({
        current,
        pageSize,
        sortKey: sorter.field,
        sort: sorter.order,
        ...filters,
      });

      reload();
    };
    const { total, pageSize, current } = toRefs(state);

    const pagination = reactive({
      showSizeChanger: true,
      showQuickJumper: false,
      pageSizeOptions: ["30", "50", "100"],
      current,
      pageSize,
      total,
      onChange: (page, pageSize) => {
        setPageInfo({ current: page, pageSize });
        reload();
      },
      onShowSizeChange: (current, size) => {
        setPageInfo({ current, pageSize: size });
        reload();
      },
    });

    let queryParam = reactive({
      mapName: "",
    });

    const handleSearch = () => {
      setPageInfo({ current: 1, ...toRaw(queryParam) });
      reload();
    };

    const handleInfo = (type, data) => {
      mapDetail(data.taskId).then((res) => {
        emit("handle", type, res.data);
      });
    };

    return {
      ...toRefs(stateData),
      state,
      pagination,
      handleTableChange,
      queryParam,
      handleSearch,
      handleInfo,
    };
  },
};
</script>

<style lang="less" scoped>
.table-wrap {
  .search {
    .mixinFlex(flex-end);
    margin: 8px 0 24px;

    ::v-deep(.ant-input-search) {
      padding-right: 1px;
      border-radius: 4px 0 0 4px;
    }

    ::v-deep(.ant-btn) {
      border-radius: 0 4px 4px 0;
    }
  }
  .action {
    ::v-deep(.ant-btn) {
      padding-right: 4px;
      padding-left: 0;
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
</style>
