import request from '@/utils/request';

// 获取学习地图目录
export const mapFolder = params => request.get(`study/map/folder/tree`, params)

// 获取学习地图列表
export const mapList = params => request.post(`study/map/list`, params)

// 获取学习地图详情
export const mapDetail = mapId => request.get(`study/map/${mapId}/detail`)

// 获取学习地图关卡详情
export const mapStageDetail = (mapId, stageId) => request.get(`study/map/${mapId}/${stageId}/detail`)

// 学习地图关卡正在学习人员
export const mapLearning = (stageId) => request.get(`study/map/${stageId}/learning`)

// 学习地图排行榜
export const mapRanking = mapId => request.get(`study/map/${mapId}/ranking`)

// 学习地图关卡解锁
export const mapUnlock = (mapId, params) => request.post(`study/map/${mapId}/unlock`, params)

// 获取学员帮带&学习记录信息
export const teachDetail = (mapId, detailId) => request.get(`study/map/${mapId}/teach/detail/${detailId}`)

// 帮带导师计划确认
export const planConfirm = params => request.post(`study/map/teach/plan/confirm`, params)

// 帮带总结提交
export const summarizeSubmit = params => request.post(`study/map/teach/summarize/submit`, params)

// 帮带问卷提交 evaltype 1老师评价学员 2学员评价老师 
export const evaluationSubmit = (ids, params) => request.post(`study/map/${ids.taskId}/${ids.detailId}/teach/evaluation/${ids.evaluationId}/${ids.targetUserId}/${ids.evaltype}/sbumit`, params)

// 帮带问卷查看
export const evaluationResult = (ids) => request.get(`study/map/${ids.taskId}/${ids.detailId}/teach/evaluation/${ids.evaluationId}/${ids.targetUserId}/${ids.evaltype}/result`)

// 帮带管理 帮带任务
export const teachManageList = params => request.post(`study/map/teach/manage/list`, params)

// 帮带管理 安排计划列表
export const teachManagePlanList = params => request.post(`study/map/teach/manage/plan/list`, params)

// 帮带管理 安排计划
export const teachManagePlan = params => request.post(`study/map/teach/plan/making`, params)

// 帮带管理 评价学员列表
export const teachManageEvaluationList = params => request.post(`study/map/teach/manage/evaluation/list`, params)

// 帮带管理 批阅总结列表
export const teachManageReviewList = params => request.post(`study/map/teach/manage/review/list`, params)

// 帮带管理 批阅总结
export const teachManageReview = params => request.post(`study/map/teach/summarize/review`, params)

// 帮带管理 学习跟踪
export const teachManageTrackList = params => request.post(`study/map/teach/manage/track/list`, params)

// 帮带管理 学习跟踪 人员
export const teachManageTrackMember = (mapId, params) => request.post(`study/map/teach/manage/track/${mapId}/member/list`, params)